<template>
    <div class="thank-you-container">
    <h1>Gracias por su compra!</h1>
    <img :src="productImage" :alt="productName" class="product-image">
    <p class="product-description">
        Para comenzar tu aprendizaje ahora, puedes hacer click en "Ver Cursos". Buena suerte!
    </p>
    <button @click="toCourses" class="toCourses">Ver Cursos</button>
  </div>
     
 </template>
 <script>
 export default {
     name: 'Purchased',
     props:{
        productSlug:String
     },
     data() {
         return {
            productImage:"",
            productName:""
         }
     },
     created() {
         this.getPurchaseInfo();
     },
     methods: {
        async getPurchaseInfo() {
      const { data } = await this.$axios.get(
        `/public/course/details/${this.productSlug}`
      );
      this.productImage=data.portada_url;
      this.productName=data.nombre;
      
    },
        toCourses(){
            this.$router.push(
                '/mis-cursos'
            )
        }
     }
 }
 </script>
 <style scoped>
 .thank-you-container {
   text-align: center;
   margin: 50px auto;
 }
 
 .product-image {
   max-width: 500px;
   width:50%;
   height: auto;
   margin-top: 20px;
 }
 
 .product-description {
   margin: 20px 0;
 }
 
 .toCourses {
   padding: 10px 20px;
   font-size: 16px;
   background-color: #4CAF50;
   color: #fff;
   border: none;
   border-radius: 5px;
   cursor: pointer;
 }
 .toCourses:hover{
    filter:brightness(120%);
 }
 </style>