<template>
    <div class="purchase-failure">
      <div class="message-container">
        <img src="../../../../assets/failure.png" alt="Failure Icon" class="failure-icon">
        <p class="failure-message">Lo sentimos. Su compra no pudo ser confirmada, por favor refresque la página o comuníquese con nosotros.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Failed',
  }
  </script>
  
  <style scoped>
  .purchase-failure {
    position: fixed;
    top: 50%; /* Adjust as needed */
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; /* Ensure it appears above other content */
  }
  
  .message-container {
    text-align: center;
    background-color: #ffffff; /* White background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
  }
  
  .failure-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }
  
  .failure-message {
    font-size: 18px;
    color: #333333; /* Dark gray text color */
  }
  </style>