<template>
    <div v-if="!isLoading">
        <Purchased v-if="paid" :productSlug="product_slug"></Purchased>
        
            <Failed v-else></Failed>
       
       
    
    </div>
       
    
</template>
<script>
import Purchased from './Purchased.vue';
import Failed from './Failed.vue';
export default {
    name: 'Order',
    components:{
        'Purchased':Purchased,
        'Failed':Failed
    },
    data() {
        return {
            session_id: "",
            product_slug:"",
            paid:false,
            status: "",
            customerEmail: "", 
            message:"",
            isLoading:true
        }
    },
    created() {
        this.session_id = this.$route.query.session_id;
        this.product_slug= this.$route.query.product_slug;
        this.grabOrderInfo(this.session_id);
    },
    methods: {
        async grabOrderInfo(session_id) {
            const {data:result} = await this.$axios.post(
            "/public/checkout/grabOrderInfo",
            JSON.stringify({session_id:session_id})
          );

            if(result.error){
                this.message=result.error;
            }else{
                this.status=result.status;
                this.paid=result.payment_status==="paid"?true:false;
                this.customerEmail=result.customer_email;
            }
            this.isLoading=false;
        }
    }
}
</script>